import "./src/styles.css";

export const onRouteUpdate = ({ location }) => {
    // Map old URLs to new ones
    const oldToNewUrlMap = {
        '/machnews.html': '/news',
        '/machfriends.html': '/friends',
        '/machbiog.html': '/biography',
        '/machlinx.html': '/contact',
    };

    const newUrl = oldToNewUrlMap[location.pathname];

    if (newUrl) {
        window.location.replace(newUrl);
    }
};